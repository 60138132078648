<template>
  <div class="index-page container">
    <n-card :loading="$var('load') || $var('loadEmails')" style="max-width: 1200px;">
      <n-form @submit="submit">
        <n-items>
          <n-select title="От кого *" :data="emails" v-bind="$form.input('email', 'select', formName)"
                    option-title="email" selected-title="email" />
          <n-input title="Кому *" v-bind="$form.input('to', undefined, formName)" :input="() => {}" :update:value="(v) => $form.set('to', v, formName)" />
          <n-input title="Копия" v-bind="$form.input('toCC', undefined, formName)" :input="() => {}" :update:value="(v) => $form.set('toCC', v, formName)" />
          <n-input title="Скрытая копия" v-bind="$form.input('toBCC', undefined, formName)" :input="() => {}" :update:value="(v) => $form.set('toBCC', v, formName)" />
          <n-input title="Заголовок *" v-bind="$form.input('subject', undefined, formName)" />
          <n-form-item v-if="$form.get('files', undefined, formName)" title="Файлы">
            <div v-for="file in $form.get('files', undefined, formName)" :key="file.id" class="file">
              <a :href="file.src" target="_blank">{{ file.name }}</a>
            </div>
          </n-form-item>
          <n-textarea title="Текст письма" v-bind="$form.input('body', undefined, formName)" />
          <n-select title="Подпись" :value="sign" :data="signs" :update:value="changeSign" item-value="id" />
          <n-textarea v-if="sign.id" title="Текст подписи" v-bind="$form.input('sign', undefined, formName)" />

          <n-upload title="Приложения" multi :value.sync="files" />

          <n-select v-if="orderType === 'customer'" :data="kps" title="Коммерческое предложение" :value.sync="kp" item-value="id" />
          
          <n-button color="primary" wide type="submit">Отправить</n-button>
        </n-items>
      </n-form>
    </n-card>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    files: [],
    emails: [],
    contacts: [],
    order: {},
    letter: {},
    sign: { id: 0, title: 'Без подписи', content: '', },
    signs: [],
  }),
  computed: {
    type() { // new, answer, answerAll, resend
      return this.$route.query.type || 'new'
    },
    orderId() {
      return this.$route.query.orderId
    },
    orderType() { // customer, provider
      return this.$route.query.orderType
    },
    letterId() { // when type = answer or answerAll or resend
      return this.$route.query.letterId
    },
    kps() {
      return $n.filter(this.order.documents, [ 'type', 'commercialOffer', ])
    },
    kp: {
      get() {
        if (this.$route.query.kp) {
          return $n.find(this.kps, [ 'id', this.$route.query.kp * 1, ])
        }
        return null
      },
      set(kp) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            kp: kp.id,
          },
        })
      },
    },
    formName() {
      let name = 'letter'
      if (this.order.id) {
        name += 'order-' + this.order.id
      } else if (this.letter.id) {
        name += 'letter-' + this.letter.id
      }

      return name
    },
  },
  watch: {
    letterId() {
      this.loadLetter()
    },
    orderId() {
      this.loadOrder()
    },
    letter() {
      this.initForm()
    },
    order() {
      this.initForm()
    },
  },
  created() {
    this.load()
    this.loadSigns()
  },
  methods: {
    initForm() {
      if (this.order.id) {
        const to = this.orderType === 'customer' && this.order.contact ? [ this.order.contact.email, ] : []
        this.$form.init({
          email: this.emails[0],
          subject: 'APP#'+this.order.number+'. ',
          body: '',
          sign: '',
          to,
          toCC: [],
          toBCC: [],
        }, undefined, this.formName)
      } else if (this.letter.id) { // type = answer or answerAll or resend
        let email = null
        const recipients = {
          to: [],
          toCC: [],
          toBCC: [],
        }
        const subject = ([ 'answer', 'answerAll', ].includes(this.type) ? 'Re: ' : 'Fwd: ') + this.letter.subject
        const body = '<br /><br />-------- Пересылаемое сообщение --------<br />' +
            this.letter.body +
            '<br />-------- Конец пересылаемого сообщения --------'

        if (this.emails.length) {
          email = this.emails[0]
          if ([ 'answer', 'answerAll', ].includes(this.type)) {
            const isMe = $n.find(this.emails, [ 'email', this.$form.get('fromEmail'), ])
            if (isMe) {
              email = isMe
            } else {
              recipients.to.push(this.letter.fromEmail)
            }
            if (this.type === 'answerAll') {
              $n.each(this.letter.recipients, (item) => {
                if (item.email === email.email) {
                  return
                }
                if (item.type === 'to') {
                  recipients.to.push(item.email)
                } else if (item.type === 'cc') {
                  recipients.toCC.push(item.email)
                } else if (item.type === 'bcc') {
                  recipients.toBCC.push(item.email)
                }
              })
            }
          }
        }

        this.$form.init({
          letterId: this.letter.id,
          email,
          subject,
          body,
          sign: '',
          ...recipients,
        }, undefined, this.formName)
      } else {
        this.$form.init({
          email: this.emails[0],
          subject: '',
          body: '',
          sign: '',
          to: [],
          toCC: [],
          toBCC: [],
        }, undefined, this.formName)
      }
    },
    load() {
      this.$var('loadEmails', true)
      const apis = [
        $api.my.emails(),
        // $api.app.contacts.get().all(),
      ]
      Promise.all(apis).then((result) => {
        this.emails = result[0].data.content
        // this.contacts = result[1].data.content
        this.initForm()
        this.loadLetter()
        this.loadOrder()
      }).finally(() => {
        this.$var('loadEmails', false)
      })
    },
    loadLetter() {
      if (!this.letterId) return
      this.$var('load', true)
      $api.email.letters.get(this.letterId).with('email').with('recipients').with('files').then((result) => {
        this.letter = result.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadOrder() {
      if (!this.orderId) return
      this.$var('load', true)
      $api.app.orders.get(this.orderId).with('contact').with('documents', (q) => q.with('file')).then((result) => {
        this.order = result.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadSigns() {
      $api.auth.signs.get($app.auth.user().id).then((result) => {
        this.signs = [
          { id: 0, title: 'Без подписи', content: '', },
          ...result.data.content,
        ]
      })
    },
    changeSign(value) {
      this.$form.set('sign', value.content, this.formName)
      this.sign = value
    },
    submit() {
      this.$var('load', true)
      if (this.files.length) {
        const apis = $n.map(this.files, (item) => $api.files.create({ file: item, }))
        Promise.all(apis).then((results) => {
          const files = $n.map(results, (item) => item.data.content.id)
          this.save(files)
        }).catch(() => {
          this.$var('load', false)
        })
      } else {
        this.save()
      }
    },
    save(files = []) {
      if (this.kp?.file?.id) {
        files.push(this.kp.file.id)
      }

      const recipients = []
      $n.each(this.$form.get('to', this.formName), (i) => recipients.push({ email: i, type: 'to', }))
      $n.each(this.$form.get('toCC', this.formName), (i) => recipients.push({ email: i, type: 'cc', }))
      $n.each(this.$form.get('toBCC', this.formName), (i) => recipients.push({ email: i, type: 'bcc', }))
      const data = {
        ...this.$form.get(undefined, this.formName),
        body: this.$form.get('body', this.formName) + this.$form.get('sign', this.formName),
        recipients,
        files,
      }
      $api.email.letters.create(this.$form.get('email', this.formName).id, data).then((response) => {
        const letter = response.data.content
        if (letter.applicationId) {
          this.$router.push({ name: 'orders.index', params: { id: letter.applicationId, }, query: { page: letter.type, letter: letter.id, }, })
        } else {
          this.$router.push({ name: 'inbox.list', })
        }
        this.$form.kill(this.formName)
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .index-page {

    &::v-deep {
      .editr--content {
        height: 500px;
      }
    }
  }
</style>
