<template>
  <div class="customers-list">
    <n-card>
      <div class="header">
        <div>
          <div class="filters">
            <n-search :value="params.filter.title" placeholder="Поиск по названию" @update:value="search" />
          </div>
        </div>
        <div class="add-button">
          <n-button @click="$var('create', true)">Новый заказчик</n-button>
        </div>
      </div>

      <n-data-component ref="data" :api="api" :data.sync="customers" :loading.sync="loading" v-bind="params" />
      <n-table :data="customers" :columns="columns" :loading="loading">
        <template #createdAt="{item}">
          {{ $app.date.format(item.createdAt) }}
        </template>
        <template #tools="{item}">
          <n-link :to="{ query: { ...$route.query, customer: item.id, }, }">
            <n-button icon="arrow-right" round />
          </n-link>
        </template>
      </n-table>
    </n-card>

    <n-modal v-if="$var('create')" class="create" :loading="$var('loadModal')" @close="$var('create', false)">
      <n-form @submit="submit">
        <div class="form">
          <h3>Создать заказчика</h3>
          <div>
            <n-items>
              <n-input title="Введите название компании *" v-bind="$form.input('title')"></n-input>
            </n-items>
            <div class="inputs-container">
              <h3 slot="header">Контакт</h3>
              <n-items>
                <n-input title="ФИО" v-bind="$form.input('contact.title')"></n-input>
                <n-input title="Телефон" v-bind="$form.input('contact.phone')"></n-input>
                <n-input title="Email *" type="email" v-bind="$form.input('contact.email')"></n-input>
              </n-items>
            </div>
            <div class="buttons-container">
              <n-button @click="$var('create', false)">Отмена</n-button>
              <n-button color="success" type="submit">Создать</n-button>
            </div>
          </div>
        </div>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
export default {
  name: 'CustomerList',
  components: {
  },
  data: () => ({
    loading: false,
    params: {
      filter: {},
    },
    customers: [],
    columns: [
      { name: 'tools', title: '', },
      { name: 'title', title: 'Наименование', },
      { name: 'callsCount', title: 'Кол-во обращений', },
      { name: 'processedCount', title: 'Кол-во отправленных КП', },
      { name: 'finishedCount', title: 'Кол-во полученных PO', },
    ],
    selectData: [],
  }),
  computed: {
    api() {
      return () => $api.app.customers.get().with('categories').with('processedCount').with('callsCount').with('finishedCount')
    },
  },
  mounted() {
    this.loadCategories()
    this.$form.init({
      name: '',
      categories: [],
      contact: {
        name: '',
        phone: '',
        email: '',
      },
    })
    this.$form.rules({
      title: [ 'required', ],
      'contact.email': [ 'required', 'email', ],
    })
  },
  methods: {
    search(value) {
      this.params = {
        ...this.params,
        filter: {
          ...this.params.filter,
          title: value,
        },
      }
    },
    check() {
      if (this.$form.check()) {
        this.submit()
      }
    },
    submit() {
      if (this.$form.check()) {
        this.$var('loadModal', true)
        const data = {
          ...this.$form.get(),
          ...$n.map(this.$form.get('categories'), (item) => item.id),
        }
        $api.app.customers.create(data).then(() => {
          this.$var('create', false)
          this.$refs.data.reload()
        }).finally(() => {
          this.$form.init()
          this.$var('loadModal', false)
        })
      }
    },
    loadCategories() {
      this.$var('loadModal', true)
      $api.app.categories.get().then((response) => {
        this.selectData = response.data.content
      }).finally(() => {
        this.$var('loadModal', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
  .customers-list {
    .filters {
      width: 400px;
      margin: 20px 0;
      &>* {
        margin: 10px 0;
      }
    }
    .header {
      width: 100%;
      display: flex;
      align-items: center;
        .add-button{
          padding:0 20px 20px 0;
          width: 100%;
          display: flex;
          justify-content: right;
        }
        .select {
          padding-bottom: 20px;
          width: 300px
        }
    }
    .create {
      .form {
        & h3 {
          text-align: center;
        }
        .inputs-container {
          & h3 {
            text-align: center
          }
          margin: 50px 0 30px 0;
        }
        .buttons-container {
          display:flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
</style>
